<template>
  <div class="home-entry">
    <p class="top_text">智慧病房护理信息对讲系统</p>
    <div class="container"></div>
    <div class="container_content">
      <div class="content_one">
        <div class="content_img">
          <div class="img com_img"></div>
        </div>
        <div class="content_text">呼叫处理,双向对讲</div>
      </div>

      <div class="content_one">
        <div class="content_img">
          <div class="img1 com_img"></div>
        </div>
        <div class="content_text">信息显示、呼叫处理、双向对讲</div>
      </div>

      <div class="content_one">
        <div class="content_img">
          <div class="img2 com_img"></div>
        </div>
        <div class="content_text">防水防尘IP68,一键紧急呼叫</div>
      </div>

      <div class="content_one">
        <div class="content_img">
          <div class="img4 com_img"></div>
        </div>
          <div class="content_text">宣教信息显示、呼叫信息显示</div>
      </div>

      <div class="content_one">
        <div class="content_img">
          <div class="img5 com_img"></div>
        </div>
          <div class="content_text">体温自动采集上传</div>
      </div>

      <div class="content_one">
        <div class="content_img">
          <div class="img6 com_img"></div>
        </div>
          <div class="content_text">输液完毕自动报警</div>
      </div>

      <div class="content_one">
        <div class="content_img">
          <div class="img3 com_img"></div>
        </div>
        <div class="content_text">
          <h4 style="margin-top: -45px;">呼叫护士、紧急增援、双工对讲、床位清理</h4>
          <h4 style="margin-top: 40px;">信息显示、费用查询、消息通知、健康宜教</h4>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HomePage',
  components: { }
}
</script>
<style scoped lang="less">
.top_text{
  width: 1024px;
  height: 60px;
  margin: 0 auto;
  text-align: center;
  line-height: 60px;
  font-size: 40px;
}
.container{
  width: 1024px;
  height: 500px;
  background: url('../../assets/images/banner/banner1.png') no-repeat center / contain;
}
.container_content{
  width: 1024px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  .content_one{
    width: 100%;
    height: 240px;
    margin-top: 20px;
    margin-bottom: 25px;
    display: flex;
    background: white;
    .content_img{
      width: 200px;
      height: 200px;
      flex: 0.3;
      .com_img{
        width: 200px;
        height: 200px;
        margin: 0 auto;
        margin-top: 25px;
      }
      .img{
        background: url('../../assets/images/company_img/3.png') no-repeat center / contain;
      }
      .img1{
        background: url('../../assets/images/company_img/4.png') no-repeat center / contain;
      }
      .img2{
        background: url('../../assets/images/company_img/6.png') no-repeat center / contain;
      }
      .img3{
        background: url('../../assets/images/company_img/21.png') no-repeat center / contain;
      }
      .img4{
        background: url('../../assets/images/company_img/5.png') no-repeat center / contain;
      }
      .img5{
        background: url('../../assets/images/company_img/7.png') no-repeat center / contain;
      }
      .img6{
        background: url('../../assets/images/company_img/8.png') no-repeat center / contain;
      }
    }
    .content_text{
      flex: 0.7;
      text-align: center;
      margin-top: 100px;
      font-size: 30px;
      font-weight: bold;
    }
  }

}
</style>
